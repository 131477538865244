import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    padding: "5px",
    width: "95%",
    margin: "0px auto",
    display: "flex",
  },
  columns: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "5px 0",
    flexBasis: "100%",
    borderRadius: 12,
    overflow: "hidden",
    "@media (min-width:980px)": {
      marginBottom: "5px",
      flexBasis: "40%",
      "&:nth-last-child(2)": {
        marginRight: 0,
      },
      "&:last-child": {
        flexBasis: "100%",
        margin: 0,
      },
    },
    "@media (min-width:680px)": {
      flexBasis: "100%",
      margin: "0 0 5px 0",
    },
  },
});

export default function ResponsiveLayoutWrapper({ children, quicklinks }) {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        maxWidth: quicklinks ? 980 : 960,
      }}
    >
      <section className={classes.columns}>{children}</section>
    </div>
  );
}
