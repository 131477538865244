import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  tabBar: {
    height: 38,
    display: "flex",
    alignItems: "top",
    justifyContent: "flex-start",
    "&::after": {
      content: '""',
      borderBottom: "1px solid rgba(0,0,0,0.2)",
      flexGrow: 1
    }
  }
});

function TabBar({ children }) {
  const classes = useStyles();

  return <div className={classes.tabBar}>{children}</div>;
}

export default TabBar;
