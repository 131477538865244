import React from "react";
import clsx from "clsx";
import MakeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = MakeStyles((theme) => ({
  main: {
    fontFamily: "Maven Pro, sans-serif",
    fontStyle: "normal",
    fontSize: "12px",
    margin: 0,
    padding: 0,
  },
  largeHeader: {
    fontSize: "32px",
  },
  normalHeader: {
    fontSize: "18px",
  },
  grey: {
    color: "#757575",
  },
  red: {
    color: "#FF3B30",
  },
  bold: {
    fontWeight: "bold",
  },
}));

function Text({
  children,
  bold,
  largeHeader,
  normalHeader,
  grey,
  red,
  color,
  margin,
  ...rest
}) {
  const classes = useStyles();

  return (
    <p
      className={clsx(classes.main, {
        [classes.largeHeader]: largeHeader,
        [classes.normalHeader]: normalHeader,
        [classes.grey]: grey,
        [classes.red]: red,
        [classes.bold]: bold,
      })}
      style={{ color, margin }}
      {...rest}
    >
      {children}
    </p>
  );
}

export default Text;
