import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    width: "100%",
    padding: "0px 16px",
    fontSize: 12,
    lineHeight: "14px",
    flexGrow: 1,
  },
}));

export default function PanelText({ children, ...rest }) {
  const classes = useStyles();

  return (
    <div {...rest} className={classes.text}>
      {children}
    </div>
  );
}
