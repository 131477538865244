import React from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Text from "../Text";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minWidth: 130,
    minHeight: "calc(300px - 30vw)",
    height: 150,
    maxHeight: 150,
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    flexShrink: 0,
    cursor: "pointer",
  },
  selected: {
    borderBottom: "5px solid #6bbfb9",
  },
}));

export default function QuickLink({ image, title, selected, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, { [classes.selected]: selected })}
      style={{
        backgroundColor: selected ? "#161b45" : "#FFFFFF",
        color: selected ? "#FFFFFF" : "#161b45",
      }}
      {...rest}
    >
      <div
        style={{
          width: "100%",
          height: "80%",
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
      ></div>
      <Text
        color={selected ? "#FFFFFF" : "#161b45"}
        margin={selected ? 10 : 15}
        bold
        style={{ textDecoration: "none" }}
      >
        {title}
      </Text>
    </div>
  );
}
