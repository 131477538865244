import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "white",
    cursor: "pointer",
    "&:title": {
      textDecoration: "none",
    },
  },
}));

function TopNavigationBarLink({ children, component, light, ...rest }, ref) {
  const classes = useStyles();

  const LinkComponent = component || "div";

  return (
    <LinkComponent ref={ref} className={classes.root} {...rest}>
      <div style={{ textDecoration: "none", color: "#FFFFFF" }}>{children}</div>
    </LinkComponent>
  );
}

export default React.forwardRef(TopNavigationBarLink);
