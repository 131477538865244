import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  tableRow: {
    display: "table-row",
    width: "100%",
    textDecoration: "initial",
    color: "inherit",
    verticalAlign: "middle"
  },
  hover: {
    "&:hover": {
      background: theme.palette.primary[300],
      cursor: "pointer"
    }
  },
  collapsed: {
    height: 0
  }
}));

function TableRow({ children, disableHover, component, collapse, ...rest }) {
  const classes = useStyles();
  // const { columnWidths } = React.useContext(TableContext);

  const TableRowComponent = component || "tr";

  return (
    <TableRowComponent
      className={clsx(classes.tableRow, {
        [classes.hover]: !disableHover,
        [classes.collapsed]: collapse
      })}
      {...rest}
    >
      {children}
    </TableRowComponent>
  );
}

export default TableRow;
