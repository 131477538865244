import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";
import MuiPopover from "@material-ui/core/Popover";
import MuiButtonBase from "@material-ui/core/ButtonBase";
import { FixedSizeList as List } from "react-window";

import VirtualizedScrollbars from "../VirtualizedScrollbars";

const useStyles = makeStyles(theme => ({
  option: {
    height: 24,
    justifyContent: "flex-start",
    width: "100%",
    padding: [[0, 8]],

    "&:hover": {
      background: "#eee"
    }
  },
  popover: {
    width: 380,
    maxHeight: 600
  },
  defaultWrapper: {
    height: 400,
    padding: 8,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    textAlign: "center"
  }
}));

function PopoverPicker({
  open,
  onClose,
  loading,
  error,
  onOptionClicked,
  options,
  renderOption,
  renderAnchor
}) {
  const classes = useStyles();
  const anchorRef = React.useRef(null);
  const listRef = React.useRef();

  const handleClose = () => {
    onClose && onClose();
  };
  const handleOptionClicked = o => {
    onOptionClicked && onOptionClicked(o);
  };

  return (
    <React.Fragment>
      {renderAnchor(anchorRef)}
      <MuiPopover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transitionDuration={200}
      >
        <div className={classes.popover}>
          {loading ? (
            <div className={classes.defaultWrapper}>
              <CircularProgress size={16} className={classes.spinner} />
            </div>
          ) : error ? (
            <div className={classes.defaultWrapper}>{error}</div>
          ) : (
            <List
              ref={listRef}
              className="List"
              height={400}
              itemCount={options.length}
              itemSize={24}
              width={380}
              outerElementType={VirtualizedScrollbars}
            >
              {({ index, style }) => {
                const o = options[index];

                return (
                  <MuiButtonBase
                    style={style}
                    onClick={() => {
                      handleOptionClicked(o);
                    }}
                    className={classes.option}
                  >
                    {renderOption(o, index)}
                  </MuiButtonBase>
                );
              }}
            </List>
          )}
        </div>
      </MuiPopover>
    </React.Fragment>
  );
}

export default PopoverPicker;
