import React from "react";
import { makeStyles } from "@material-ui/core";
import MuiPopover from "@material-ui/core/Popover";

const useStyles = makeStyles(theme => ({
  popover: {
    width: 380,
    maxHeight: 600
  }
}));

function PopoverMenu({ open, onClose, children, renderAnchor }) {
  const classes = useStyles();
  const anchorRef = React.useRef(null);

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <React.Fragment>
      {renderAnchor(anchorRef)}
      <MuiPopover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transitionDuration={200}
      >
        <div className={classes.popover}>{children}</div>
      </MuiPopover>
    </React.Fragment>
  );
}

export default PopoverMenu;
