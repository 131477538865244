import React from "react";
import {
  makeStyles,
  Dialog as MuiDialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
  dialogPaper: {
    width: 474,
    border: "1px solid #E3E9EF"
  }
});

function FormDialog({ open, onClose, children, width }) {
  const classes = useStyles();

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.dialogPaper
      }}
      PaperProps={{
        style: width ? { width } : {}
      }}
      maxWidth={false}
    >
      {children}
    </MuiDialog>
  );
}

export default FormDialog;
