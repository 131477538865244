import React from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    flex: 1,
    display: "flex",
    alignContent: "center",
    alignItems: "center",
  },
  wrap: {
    flexWrap: "wrap",
  },
  start: {
    justifyContent: "flex-start",
  },
  center: {
    justifyContent: "center",
  },
  end: {
    justifyContent: "flex-end",
  },
  spaceAround: {
    justifyContent: "space-around",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
});

export default function Flex({
  children,
  start,
  center,
  spaceAround,
  spaceBetween,
  end,
  wrap,
}) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.start]: start,
        [classes.center]: center,
        [classes.spaceAround]: spaceAround,
        [classes.spaceBetween]: spaceBetween,
        [classes.end]: end,
        [classes.wrap]: wrap,
      })}
    >
      {children}
    </div>
  );
}
