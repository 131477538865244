import React from "react";
import clsx from "clsx";
import { makeStyles, Divider, CircularProgress } from "@material-ui/core";
import MuiPopover from "@material-ui/core/Popover";
import MuiInputBase from "@material-ui/core/InputBase";
import MuiButtonBase from "@material-ui/core/ButtonBase";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import Scrollbars from "react-custom-scrollbars";
import { FixedSizeList as List } from "react-window";

import VirtualizedScrollbars from "../VirtualizedScrollbars";

const useStyles = makeStyles(theme => ({
  wrapper: {
    fontFamily: "Open sans",
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.primary[200]}`,
    background: theme.palette.primary[100],
    height: 32,
    borderRadius: 2,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 280
  },
  root: {
    height: 32,
    // padding: "0 8px",
    flexGrow: 1
  },
  placeholder: {
    opacity: 0.5
  },
  input: {
    padding: [[0, 8]],
    flexGrow: 1
  },
  select: {
    fontSize: 14,
    height: 32,
    padding: "0 8px"
  },
  button: {
    paddingRight: 8
  },
  option: {
    justifyContent: "flex-start",
    width: "100%",
    padding: [[0, 8]],

    "&:hover": {
      background: "#eee"
    }
  },
  selectedOption: {
    background: "#f0f0f0"
  },
  popover: {
    width: 380,
    maxHeight: 600
  },
  valueWrapper: {
    height: "100%",
    flexGrow: 1,
    padding: [[0, 8]],
    justifyContent: "flex-start"
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "0 5px 0 0"
  },
  defaultWrapper: {
    height: 400,
    padding: 8,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    textAlign: "center"
  }
}));

function SearchableDropdown(
  {
    width,
    value,
    onChange,
    search,
    onSearchChange,
    options,
    renderOption,
    renderValue,
    placeholder,
    disabled,
    loading,
    error,
    fallback,
    listHeading,
    itemHeight = 24,
    itemStyles = {}
  },
  ref
) {
  const classes = useStyles();
  const anchorEl = React.useRef(null);
  const listRef = React.useRef();
  const [open, setOpen] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    scrollToItem: listRef.current && listRef.current.scrollToItem
  }));

  const listRefCallback = React.useCallback(
    node => {
      if (node) {
        const index = Array.isArray(options)
          ? options.findIndex(v => v === value)
          : -1;
        console.log("found item at index: ", index);
        if (index != -1) {
          node.scrollToItem(index, "center");
        }

        // Check if a node is actually passed. Otherwise node would be null.
        // You can now do what you need to, addEventListeners, measure, etc.
      }

      // Save a listReference to the node
      listRef.current = node;
    },
    [options, value]
  );

  const handleOpen = event => {
    if (!disabled) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  function handleClear() {
    if (!disabled) onChange(null);
  }

  function handleClearSearch() {
    onSearchChange("");
  }

  const id = open ? "searchable-dropdown" : undefined;
  placeholder = placeholder || "";

  return (
    <React.Fragment>
      <div
        className={classes.wrapper}
        ref={anchorEl}
        style={width ? { width } : {}}
      >
        <MuiButtonBase onClick={handleOpen} className={classes.valueWrapper}>
          {value == null ? (
            <div className={classes.placeholder}>{placeholder}</div>
          ) : renderValue ? (
            renderValue(value)
          ) : (
            renderOption(value, 0)
          )}
        </MuiButtonBase>
        {value != null ? (
          <MuiButtonBase
            onClick={handleClear}
            className={clsx(classes.button, classes.hover)}
          >
            <CloseIcon />
          </MuiButtonBase>
        ) : (
          <div className={classes.button} />
        )}
      </div>
      <MuiPopover
        open={open}
        anchorEl={anchorEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transitionDuration={200}
      >
        <div className={classes.popover}>
          <div className={classes.inputWrapper}>
            <MuiInputBase
              autoFocus
              placeholder="Filter..."
              className={classes.input}
              value={search}
              onChange={e => onSearchChange(e.target.value)}
            />
            {search ? (
              <MuiButtonBase
                onClick={handleClearSearch}
                className={clsx(classes.button, classes.hover)}
              >
                <CloseIcon />
              </MuiButtonBase>
            ) : (
              <div className={classes.button} />
            )}
          </div>
          <Divider />
          {listHeading}

          {loading ? (
            <div className={classes.defaultWrapper}>
              <CircularProgress size={16} className={classes.spinner} />
            </div>
          ) : error ? (
            <div className={classes.defaultWrapper}>{error}</div>
          ) : options.length === 0 ? (
            <div className={classes.defaultWrapper}>{fallback}</div>
          ) : (
            <List
              ref={listRefCallback}
              className="List"
              height={400}
              itemCount={options.length}
              itemSize={itemHeight}
              width={380}
              outerElementType={VirtualizedScrollbars}
            >
              {({ index, style }) => {
                const o = options[index];
                const selected = o === value;

                return (
                  <MuiButtonBase
                    style={{ ...style, ...itemStyles, height: itemHeight }}
                    onClick={() => {
                      onChange(o);
                      handleClose();
                    }}
                    className={clsx(classes.option, {
                      [classes.selectedOption]: selected
                    })}
                  >
                    {renderOption(o, index)}
                  </MuiButtonBase>
                );
              }}
            </List>
          )}
        </div>
      </MuiPopover>
    </React.Fragment>
  );
}

export default React.forwardRef(SearchableDropdown);
