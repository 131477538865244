import React from "react";

export default function PanelImage({ image, ...rest }) {
  return (
    <div
      style={{
        backgroundImage: `url(${image})`,
        width: "100%",
        height: "200px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        flexShrink: 0,
      }}
      {...rest}
    ></div>
  );
}
