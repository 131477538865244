import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
// import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles(theme => ({
  tableCell: {
    height: 38,
    padding: "4px 16px",
    position: "relative"
  },
  summary: {
    borderTop: "2px solid black"
  },
  shaded: {
    background: theme.palette.primary[100]
  },
  noBorderBottom: {
    borderBottom: "none"
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.primary[200]}`
  },
  mini: {
    height: 12
  },
  hoverElement: {
    padding: 20,
    // pointerEvents: "none",
    position: "absolute",
    top: 27,
    zIndex: 1000
  },
  alignRight: {
    textAlign: "right"
  }
}));

function TableCell({
  children,
  summary,
  shaded,
  noBorderBottom,
  borderRight,
  mini,
  width,
  colSpan,
  hoverElement,
  alignRight
}) {
  const classes = useStyles();
  const [showHoverElement, setShowHoverElement] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  let closeHoverTimeout = null;
  let openHoverTimeout = null;

  return (
    <MuiTableCell
      className={clsx(classes.tableCell, {
        [classes.summary]: summary,
        [classes.shaded]: shaded,
        [classes.noBorderBottom]: noBorderBottom,
        [classes.borderRight]: borderRight,
        [classes.mini]: mini,
        [classes.alignRight]: alignRight
      })}
      style={width ? { maxWidth: width, width } : {}}
      colSpan={colSpan}
      onMouseEnter={event => {
        clearTimeout(openHoverTimeout);
        openHoverTimeout = setTimeout(() => {
          setShowHoverElement(true);
          setAnchorEl(event.target);
        }, 50);
      }}
      onMouseLeave={() => {
        clearTimeout(closeHoverTimeout);
        clearTimeout(openHoverTimeout);
        closeHoverTimeout = setTimeout(() => {
          setShowHoverElement(false);
          setAnchorEl(null);
        }, 50);
      }}
    >
      {children}
      {hoverElement && showHoverElement && (
        <div className={classes.hoverElement}>
          <div
            onMouseEnter={() => clearTimeout(closeHoverTimeout)}
            onMouseLeave={() => {
              setShowHoverElement(false);
              setAnchorEl(null);
            }}
          >
            {hoverElement}
          </div>
        </div>
      )}
    </MuiTableCell>
  );
}

export default TableCell;
