import React from "react";
import clsx from 'clsx';
import { Avatar as MuiAvatar, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    height: 32,
    width: 32,
    background: "#DFEFFE",
    color: "#2895FA",
    textDecoration: "initial"
  },
  vacant: {
    background: "#FDDDDD",
    color: "#F21D1D"
  }
}));

function Avatar({ text, vacant }) {
  const classes = useStyles();

  return <MuiAvatar className={clsx(classes.root, {[classes.vacant]: vacant})}>{text}</MuiAvatar>;
}

export default Avatar;
