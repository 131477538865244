import React from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ResponsiveLayoutWrapper from "../ResponsiveLayoutWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.footer,
    height: "96px",
    width: "100%",
    color: "#161b45",
    display: "flex",
    flexShrink: 0,
    alignItems: "center",
  },
  desktop: {
    "@media (max-width:840px)": {
      display: "none",
    },
  },
  mobile: {
    "@media (min-width:840px)": {
      display: "none",
    },
  },
}));

export default function Footer({ children, mobile }) {
  const classes = useStyles();

  return (
    <>
      <div className={clsx(classes.root, classes.desktop)}>
        <ResponsiveLayoutWrapper>{children}</ResponsiveLayoutWrapper>
      </div>
      <div className={clsx(classes.root, classes.mobile)}>
        <ResponsiveLayoutWrapper>{mobile}</ResponsiveLayoutWrapper>
      </div>
    </>
  );
}
