import React from "react";
import clsx from "clsx";
import MakeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = MakeStyles({
    left: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        float: "left"
    },
    center: {
        float: "center"
    },
    right: {
        float: "right"
    }
});

function Alignment({ center, right, children, ...rest }) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.left, {
            [classes.center]: center,
            [classes.right]: right
        })} {...rest}>
            {children}
        </div>
    );
}

export default Alignment;