import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  panelHeading: {
    minHeight: 44,
    width: "100%",
    padding: "0px 16px",
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

function PanelHeading({ children, rightAction, ...rest }) {
  const classes = useStyles();

  return (
    <div {...rest} className={classes.panelHeading}>
      <div>{children}</div>
      {rightAction && <div>{rightAction}</div>}
    </div>
  );
}

export default PanelHeading;
