import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    flex: 1,
    borderRadius: 16,
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
});

export default function PanelContent({
  children,
  active,
  width,
  maxWidth,
  ...rest
}) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ width, maxWidth }} {...rest}>
      {children}
    </div>
  );
}
