import React from "react";
import MakeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = MakeStyles(theme => ({
    root: {
        display: "block",
        margin: "0 10px 0 10px",
    }
}))

function StackedText({ children }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {children}
        </div>
    );
}

export default StackedText;