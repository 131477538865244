import React from "react";
import { makeStyles, Tooltip, Paper } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  tooltip: {
    background: "initial",
    fontSize: "inherit",
    color: "inherit",
    padding: 0,
    maxWidth: "initial",
    wordWrap: "inherit",
    fontFamily: "inherit",
    fontWeight: "inherit",
    lineHeight: "inherit",
    borderRadius: 0
  }
}));

function Rollover({
  children,
  renderAnchor,
  interactive = true,
  placement = "right"
}) {
  const classes = useStyles();

  return (
    <Tooltip
      placement={placement}
      title={<Paper>{children}</Paper>}
      interactive={interactive}
      classes={classes}
    >
      {renderAnchor()}
    </Tooltip>
  );
}

export default Rollover;
