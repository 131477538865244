import React from "react";
import propTypes from "prop-types";
import clsx from "clsx";
import MuiButton from "@material-ui/core/ButtonBase";
import { makeStyles } from "@material-ui/core";

import Spinner from "../Spinner";

const useStyles = makeStyles((theme) => ({
  button: {
    boxSizing: "border-box",
    borderRadius: 5,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    padding: [[4, 12]],
    fontFamily: "Lato",
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: "19px",
    position: "relative",
  },
  large: {
    width: 175,
  },
  small: {
    height: 32,
  },
  primary: {
    color: "white",
    background: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.primary.main,
    background: theme.palette.primary.light,
  },
  cancel: {
    border: "1px solid #2895FA",
    boxSizing: "border-box",
    color: "#2895FA",
    background: "#FFFFFF",
  },
  disabled: {
    background: "#BFE0E1",
    cursor: "not-allowed",
    opacity: 0.6,
  },
  buttonSpinner: {
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "inherit",
  },
}));

function Button({
  children,
  large,
  small,
  disabled,
  cancel,
  variant = "primary",
  onClick,
  submitting,
  ...rest
}) {
  const classes = useStyles();
  return (
    <MuiButton
      disabled={disabled}
      className={clsx(classes.button, classes[variant], {
        [classes.disabled]: disabled,
        [classes.large]: large,
        [classes.small]: !large && small,
        [classes.cancel]: cancel,
      })}
      onClick={(e) => {
        //e.stopPropogation();
        if (onClick) {
          onClick(e);
        }
      }}
      {...rest}
    >
      {children}
      {submitting && (
        <div className={classes.buttonSpinner}>
          <Spinner size="small" color="#fff" />
        </div>
      )}
    </MuiButton>
  );
}

Button.propTypes = {
  children: propTypes.node,
  disabled: propTypes.bool,
  variant: propTypes.oneOf(["primary", "secondary"]),
};

export default Button;
