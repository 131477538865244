import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  panelRow: {
    minHeight: 38,
    padding: "4px 16px",
  },
  small: {
    minHeight: 32,
  },
});

function PanelRow({ children, small }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.panelRow, { [classes.small]: small })}>
      {children}
    </div>
  );
}

export default PanelRow;
