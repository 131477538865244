import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import { DateTime, Duration } from "luxon";
import { Scrollbars } from "react-custom-scrollbars";

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 10
  },

  table: {
    padding: 10,
    width: "100%",
    borderCollapse: "collapse",

    "& td": {
      //border: "1px solid #E3E9EF",
      padding: 2,
      height: 38,
      width: "calc(100% / 7)"
    }
  },

  headerColumn: {
    padding: 2,
    height: 16,
    textAlign: "center",
    fontSize: 10,
    borderBottom: "1px solid #E3E9EF"
  },

  body: {
    //height: 600,
    overflowY: "auto",
    borderBottom: "1px solid #E3E9EF"
  },

  dayTitle: {
    fontSize: 10,
    color: "rgba(0, 0, 0, 0.87)",
    opacity: 0.5
  },
  dayContent: {
    height: 38
  },

  hover: {
    "&:hover": {
      background: theme.palette.primary[300],
      cursor: "pointer"
    }
  }
}));

function Calendar({
  year,
  renderDay,
  isDateClickable = () => true,
  onDateClicked = () => {},
  height = 600
}) {
  const classes = useStyles();

  year = year || DateTime.local().year;

  const startDate = DateTime.fromObject({ year, month: 1, day: 1 }).startOf(
    "week"
  );
  const endDate = DateTime.fromObject({
    year: year + 1,
    month: 1,
    day: 1
  }).startOf("week");

  const numberOfWeeks = endDate.diff(startDate).as("weeks");

  console.log("number of weeks", numberOfWeeks);

  const weeks = [...Array(numberOfWeeks).keys()].map(n =>
    [...Array(7).keys()].map(d =>
      startDate.plus(Duration.fromObject({ weeks: n, days: d }))
    )
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td className={classes.headerColumn}>M</td>
              <td className={classes.headerColumn}>T</td>
              <td className={classes.headerColumn}>W</td>
              <td className={classes.headerColumn}>T</td>
              <td className={classes.headerColumn}>F</td>
              <td className={classes.headerColumn}>S</td>
              <td className={classes.headerColumn}>S</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={classes.body} style={{ height }}>
        <Scrollbars>
          <table className={classes.table}>
            <tbody>
              {weeks.map((week, weekIndex) => {
                return (
                  <tr>
                    {week.map((day, dayIndex) => {
                      const d = day;
                      const di = dayIndex;
                      const si = weekIndex;
                      const isFirstInMonth = day.day == 1;

                      const borderDefault = "1px solid #E3E9EF";
                      // if this date is the first of the month, so long as it's not the first in the line, have a black left border
                      const borderLeft =
                        isFirstInMonth && di != 0
                          ? "1px solid black"
                          : di == 0
                          ? "none"
                          : borderDefault;
                      // if the start of the line was in a different month, have a black top border
                      const borderTop =
                        si == 0
                          ? "none"
                          : d.minus(Duration.fromObject({ days: di + 1 }))
                              .month != d.month
                          ? "1px solid black"
                          : borderDefault;
                      // if the start of the line was in a different month, have a black top border
                      const borderBottom =
                        d.plus(Duration.fromObject({ days: 7 - 1 - di }))
                          .month != d.month
                          ? "1px solid black"
                          : "none";

                      const dayInfo = {
                        day,
                        week,
                        dayIndex,
                        weekIndex
                      };

                      const isClickable = isDateClickable(dayInfo);
                      const handleClick =
                        isClickable && onDateClicked
                          ? () => onDateClicked(dayInfo)
                          : null;

                      return (
                        <td
                          className={clsx({
                            [classes.hover]: isClickable
                          })}
                          onClick={handleClick}
                          style={{
                            width: "calc((100% / 18))",
                            borderLeft,
                            borderTop,
                            borderBottom,
                            verticalAlign: "top"
                          }}
                        >
                          <div className={classes.dayTitle}>
                            {day.toFormat("d")}
                            {day.day == 1 && day.toFormat(" MMM")}
                          </div>
                          <div className={classes.dayContent}>
                            {renderDay &&
                              //typeof (renderDay === "function") &&
                              renderDay(dayInfo)}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Scrollbars>
      </div>
    </div>
  );
}

export default Calendar;
