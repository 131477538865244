import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    fontSize: 18,
    lineHeight: "25px",
  },
});

function PageSubtitle({ children, margin, color }) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ color, margin }}>
      {children}
    </div>
  );
}

export default PageSubtitle;
