import React from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MuiInputBase from "@material-ui/core/InputBase";
import MuiButtonBase from "@material-ui/core/ButtonBase";
import MuiPopover from "@material-ui/core/Popover";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import InputBase from "@material-ui/core/InputBase";

const BACKSPACE_KEYCODE = 8;
const ENTER_KEYCODE = 13;

const useStyles = makeStyles(theme => ({
  root: {
    // border: "solid 1px #dadce0",
    border: `1px solid ${theme.palette.primary[200]}`,
    background: theme.palette.primary[100],
    borderRadius: 4,
    // width: "100%",
    width: "65%",
    height: 46,
    display: "flex",
    alignItems: "center",
    padding: "0 0 0 5px"
  },
  popover: {
    width: 800,
    minHeight: 38,
    padding: 8
  },
  tagWrapper: {
    padding: "4px 0",
    marginRight: 4,
    height: "100%"
  },
  tag: {
    height: "100%",
    background: "#dadce0",
    borderRadius: 2,
    display: "flex"
  },
  tagContent: {
    padding: "4px 8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center"
  },
  tagLabel: {
    fontSize: 8,
    lineHeight: "11px",
    textTransform: "uppercase"
  },
  tagValue: {
    lineHeight: "14px",
    fontSize: 14
  },
  tagRemoveIconWrapper: {
    display: "flex",
    alignItems: "center",
    padding: [[0, 4]]
  },
  tagRemoveIconButton: {
    height: 20,
    width: 20,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": { background: "#babdc5" }
  },
  tagRemoveIcon: {
    fontSize: "14px !important"
  },
  input: {
    height: 40,
    flexGrow: 1
  },
  button: {
    height: 36,
    width: 36,
    borderRadius: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 5
  },
  hover: {
    "&:hover": { background: "#dadce0" }
  },
  small: {
    height: 38,
    "& $input": {
      height: 32
    }
  }
}));

function SearchBar({
  value,
  onChange,
  onSubmit,
  tags,
  onDeleteTag,
  renderForm,
  enableDropdown,
  placeholder,
  small
}) {
  const classes = useStyles();

  const anchorEl = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  function handleChange(v) {
    if (onChange) onChange(v);
  }

  function handleKeyPress(e) {
    switch (e.keyCode) {
      case BACKSPACE_KEYCODE: {
        if (tags && tags.length > 0 && e.target.selectionStart === 0) {
          onDeleteTag(tags[tags.length - 1].key);
        }
        break;
      }
      case ENTER_KEYCODE: {
        if (onSubmit) onSubmit();
        break;
      }
    }
  }

  const handleClear = () => {
    handleChange("");
  };

  const handleOpen = event => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const id = open ? "simple-popover" : undefined;

  return (
    <React.Fragment>
      <div
        className={clsx(classes.root, { [classes.small]: small })}
        ref={anchorEl}
      >
        <div className={classes.button}>
          <SearchIcon />
        </div>
        {tags &&
          tags.map(tag => (
            <div className={classes.tagWrapper} key={tag.key}>
              <div className={classes.tag}>
                <div className={classes.tagContent}>
                  <div className={classes.tagLabel}>{tag.key}</div>
                  <div className={classes.tagValue}>{tag.value}</div>
                </div>
                <div className={classes.tagRemoveIconWrapper}>
                  <MuiButtonBase
                    onClick={() => onDeleteTag(tag.key)}
                    className={classes.tagRemoveIconButton}
                  >
                    <CloseIcon className={classes.tagRemoveIcon} />
                  </MuiButtonBase>
                </div>
              </div>
            </div>
          ))}
        <MuiInputBase
          placeholder={placeholder}
          onKeyDown={handleKeyPress}
          className={classes.input}
          value={value}
          onChange={e => handleChange(e.target.value)}
        />
        {value ? (
          <MuiButtonBase
            onClick={handleClear}
            className={clsx(classes.button, classes.hover)}
          >
            <CloseIcon />
          </MuiButtonBase>
        ) : (
          <div className={classes.button} />
        )}
        {enableDropdown &&
          (!open ? (
            <MuiButtonBase
              onClick={handleOpen}
              className={clsx(classes.button, classes.hover)}
            >
              <ExpandMoreIcon />
            </MuiButtonBase>
          ) : (
            <div className={classes.button} />
          ))}
      </div>
      {enableDropdown && (
        <MuiPopover
          id={id}
          open={open}
          anchorEl={anchorEl.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          transitionDuration={200}
        >
          <div className={classes.popover}>
            {renderForm && renderForm({ close: handleClose })}
          </div>
        </MuiPopover>
      )}
    </React.Fragment>
  );
}

export default SearchBar;
