import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  tableHeaderColumn: {
    height: 44,
    padding: "0px 16px",
    fontWeight: "bold",
    fontSize: 12,
    borderTop: "none",
    background: theme.palette.primary[300],
    borderColor: theme.palette.primary[200],
    lineHeight: 1.2
  },
  alignRight: {
    textAlign: "right"
  }
}));

function TableHeaderColumn({ children, width, alignRight, backgroundColor }) {
  const classes = useStyles();

  return (
    <MuiTableCell
      variant="head"
      component="th"
      className={clsx(classes.tableHeaderColumn, {
        [classes.alignRight]: alignRight
      })}
      style={
        width && backgroundColor
          ? { width, maxWidth: width, backgroundColor }
          : width
          ? { width, maxWidth: width }
          : backgroundColor
          ? { backgroundColor, borderColor: backgroundColor }
          : {}
      }
    >
      {children}
    </MuiTableCell>
  );
}

export default TableHeaderColumn;
