import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import Grow from "@material-ui/core/Grow";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

function Snack({
  open,
  onClose,
  message,
  fade,
  slide,
  autoHideDuration,
  error,
  warning,
  info,
  success
}) {
  const classes = useStyles();

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      TransitionComponent={fade ? Fade : slide ? Slide : Grow}
    >
      <div className={classes.root}>
        {error && (
          <Alert variant="filled" severity="error">
            {message}
          </Alert>
        )}
        {warning && (
          <Alert variant="filled" severity="warning">
            {message}
          </Alert>
        )}
        {info && (
          <Alert variant="filled" severity="info">
            {message}
          </Alert>
        )}
        {success && (
          <Alert variant="filled" severity="success">
            {message}
          </Alert>
        )}
      </div>
    </Snackbar>
  );
}

export default Snack;
