import React from "react";
import { makeStyles } from "@material-ui/core";
import MuiButtonBase from "@material-ui/core/ButtonBase";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    height: 38,
    justifyContent: "flex-start",
    width: "100%",
    padding: [[0, 8]],
    "&:hover": {
      background: "#eee"
    }
  }
}));

function PopoverMenuItem({ children, onClick, component, ...rest }) {
  const classes = useStyles();

  return (
    <MuiButtonBase
      className={clsx(classes.root)}
      component={component}
      onClick={onClick}
      {...rest}
    >
      {children}
    </MuiButtonBase>
  );
}

export default PopoverMenuItem;
