import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  contactCard: {
    width: 300,
    border: "1px solid #E3E9EF",
    backgroundColor: "#fff",
    borderRadius: 5,
    pointerEvents: "none"
  },
  cardHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 20,
    borderBottom: "1px solid #E3E9EF"
  },
  cardName: {
    maxWidth: 200,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  }
});

function ContactCard({ name, reference }) {
  const classes = useStyles();

  return (
    <div className={classes.contactCard}>
      <div className={classes.cardHeading}>
        <div className={classes.cardName}>{name}</div>
        <div className={classes.cardReference}>{reference}</div>
      </div>
    </div>
  );
}

export default ContactCard;
