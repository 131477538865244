import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
  trueIndicator: {
    height: 14,
    width: 14,
    borderRadius: 7,
    backgroundColor: "#9DD39F"
  },
  falseIndicator: {
    height: 14,
    width: 14,
    borderRadius: 7,
    backgroundColor: "#F69892"
  }
});

function StatusIndicator({ value }) {
  const classes = useStyles();

  if (value) {
    return <div className={classes.trueIndicator} />;
  }

  return <div className={classes.falseIndicator} />;
}

export default StatusIndicator;
