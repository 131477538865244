import React from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100%",
    overflow: "scroll",
  },
  border: {
    border: "1px solid #E5E5E5",
  },
});

export default function PageContent({ children, backgroundColor }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {})}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      {children}
    </div>
  );
}
