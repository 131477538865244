import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  root: {
    float: "left",
    background: theme.palette.background.paper,
    borderRight: "solid 1px #EAEAEA",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    flexShrink: 0
  }
}));

function NavigationBar({ children }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}

export default NavigationBar;
