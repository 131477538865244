import React from "react";
import MuiRadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  column: {},
  row: {
    flexDirection: "row"
  }
});

function RadioGroup({ value, onChange, children, column, ...rest }) {
  const classes = useStyles();

  return (
    <MuiRadioGroup
      value={value}
      onChange={onChange}
      className={column ? classes.column : classes.row}
      {...rest}
    >
      {children}
    </MuiRadioGroup>
  );
}

export default RadioGroup;
