import React from "react";
import clsx from "clsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiSlider from "@material-ui/core/Slider";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  wrapper: {
    fontFamily: "Open sans",
    boxSizing: "border-box",
    height: 32,
    borderRadius: 2,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 280,
    padding: "0 16px"
  }
}));

function Slider({ value, onChange, onBlur, step, min, max, disabled }) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <MuiSlider
        value={value}
        onChange={onChange}
        onChangeCommitted={onBlur}
        disabled={disabled}
        valueLabelDisplay="auto"
        step={step}
        marks
        min={min}
        max={max}
      />
    </div>
  );

  // return (
  //   <FormControlLabel
  //     onClick={e => e.stopPropagation()}
  //     classes={{ root: classes.root, label: classes.label }}
  //     control={
  //       <Slider
  //         className={clsx(classes.checkBox)}
  //         color="primary"
  //         checked={checked}
  //         onChange={onChange}
  //       />
  //     }
  //     label={label}
  //     labelPlacement="end"
  //     disabled={disabled}
  //   />
  // );
}

export default Slider;
