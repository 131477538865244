import React from "react";
import MakeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = MakeStyles(theme => ({
    root: {
        position: "absolute",
        left: 234,
        right: 0,
        borderBottom: "1px solid #EAEAEA",
        padding: "0px 34px",
        marginBottom: "10px"
    }
}));

function UnderlinedSection({ children, ...rest }) {
    const classes = useStyles();

    return (
        <div className={classes.root} {...rest}>
            {children}
        </div>
    );
}

export default UnderlinedSection;