import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  /* Styles applied to the root element. */
  root: {
    display: "flex",
    alignItems: "center",
    padding: "16px",
    justifyContent: "flex-start",
    flex: "0 0 auto",
  },
  /* Styles applied to the root element if `disableSpacing={false}`. */
  spacing: {
    "& > :not(:first-child)": {
      marginLeft: 8,
    },
  },
}));

const PanelActions = React.forwardRef(function PanelActions(props, ref) {
  const { disableSpacing = false, ...other } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, { [classes.spacing]: !disableSpacing })}
      ref={ref}
      {...other}
    />
  );
});

PanelActions.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * If `true`, the actions do not have additional margin.
   */
  disableSpacing: PropTypes.bool,
};

export default PanelActions;
