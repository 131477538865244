import React from "react";
import { makeStyles, IconButton, Badge } from "@material-ui/core";
import ChatIcon from "@material-ui/icons/Chat";

const useStyles = makeStyles(theme => ({
  iconButton: {
    // fontFamily: "Open sans",
    // boxSizing: "border-box",
    // border: `1px solid ${theme.palette.primary.main}`,
    height: 50,
    width: 50,
    borderRadius: 4,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center"
    // padding: [[0, 24]],
    // fontSize: 14,
    //lineHeight: "16px"
  },
  disabled: {
    opacity: 0.6
  },
  rippleChild: {
    borderRadius: 4
  },
  muted: {
    background: "#bdbdbd",
    color: "black"
  }
}));

function CommentsIconButton({ unread, count, disabled, onClick, ...rest }) {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.iconButton}
      TouchRippleProps={{ classes: { child: classes.rippleChild } }}
      onClick={e => {
        if (onClick) {
          onClick(e);
        }
      }}
    >
      <Badge
        badgeContent={count}
        max={99}
        color={unread ? "primary" : "secondary"}
        classes={{ colorSecondary: classes.muted }}
      >
        <ChatIcon />
      </Badge>
    </IconButton>
  );
}

export default CommentsIconButton;
