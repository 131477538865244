import React from "react";
import clsx from "clsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiCheckBox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-flex",
    marginRight: 8,
    marginLeft: 0,
  },
  label: {
    fontSize: 12,
  },
  checkBox: {
    height: 32,
    width: 32,
    borderRadius: 4,
    color: theme.palette.primary[200], //"#E3E9EF"
  },
  rippleChild: {
    borderRadius: 4,
  },
}));

function CheckBox({ checked, label, onChange, disabled }) {
  const classes = useStyles();

  return (
    <FormControlLabel
      onClick={(e) => e.stopPropagation()}
      classes={{ root: classes.root, label: classes.label }}
      control={
        <MuiCheckBox
          className={clsx(classes.checkBox)}
          TouchRippleProps={{
            classes: { child: classes.rippleChild },
          }}
          color="primary"
          checked={checked}
          onChange={onChange}
        />
      }
      label={label}
      labelPlacement="end"
      disabled={disabled}
    />
  );
}

export default CheckBox;
