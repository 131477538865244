import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: 474,
    borderLeft: "1px solid #E3E9EF",
    flexShrink: 0,
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto"
  },
  closed: {
    width: 0
  }
});

function SidePanel({ open, children }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, { [classes.closed]: !open })}>
      {children}
    </div>
  );
}

export default SidePanel;
