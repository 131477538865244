import React from "react";
import propTypes from "prop-types";
import clsx from "clsx";
import MuiIconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(theme => ({
  arrowBackButton: {
    boxSizing: "border-box",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    height: 45,
    width: 45, 
    marginBottom: -8
  },
  disabled: {
    opacity: 0.6
  }
}));

function BackArrow({ disabled, onClick, ...rest }) {
  const classes = useStyles();

  return (
    <MuiIconButton
      disabled={disabled}
      className={clsx(classes.arrowBackButton, {
        [classes.disabled]: disabled
      })}
      onClick={e => {
        if (onClick) {
          onClick(e);
        }
      }}
      {...rest}
    >
      <ArrowBackIcon />
    </MuiIconButton>
  );
}

export default BackArrow;
