import React from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ResponsiveLayoutWrapper from "../ResponsiveLayoutWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.topBar,
    height: 48,
    width: "100%",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
  },
  desktop: {
    "@media (max-width:840px)": {
      display: "none",
    },
  },
  mobile: {
    "@media (min-width:840px)": {
      display: "none",
    },
  },
}));

function TopBar({ children, mobile }) {
  const classes = useStyles();

  return (
    <>
      <div className={clsx(classes.root, classes.desktop)}>
        <ResponsiveLayoutWrapper>{children}</ResponsiveLayoutWrapper>
      </div>
      <div className={clsx(classes.mobile, classes.root)}>
        <ResponsiveLayoutWrapper>{mobile}</ResponsiveLayoutWrapper>
      </div>
    </>
  );
}

export default TopBar;
