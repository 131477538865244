import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexGrow: 1
  }
});

function Page({ children }) {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}

export default Page;
