import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableHeader from "../TableHeader";
import TableContext from "./TableContext";
import { Paper } from "@material-ui/core";
import Scrollbars from "react-custom-scrollbars";

const useStyles = makeStyles(theme => ({
  scrollWrapper: {
    height: "100%",
    width: "100%",
    overflowX: "auto",
    border: `1px solid ${theme.palette.primary[200]}`,
    marginTop: "20px"
  },
  table: {
    height: "100%",
    minWidth: "100%"
  }
}));

function Table({ children, width, fullPageTable, standardTable, margin }) {
  const classes = useStyles();
  const tableRef = React.useRef();
  // const [tableHeight, setTableHeight] = React.useState(null);

  // const headers = React.Children.toArray(children)
  //   .filter(React.isValidElement)
  //   .filter(child => child.type === TableHeader);

  // let columnWidths = [];

  // if (headers.length) {
  //   columnWidths = React.Children.map(headers[0].props.children, child => {
  //     if (!React.isValidElement(child)) return;
  //     const { width } = child.props;
  //     return width || null;
  //   });
  // }

  // const updateTableHeight = React.useCallback(() => {
  //   if (tableRef.current) {
  //     setTableHeight(tableRef.current.clientHeight);
  //   }
  // }, []);

  // React.useLayoutEffect(updateTableHeight, []);
  // React.useEffect(() => {
  //   window.addEventListener("resize", updateTableHeight);
  //   return () => window.removeEventListener("resize", updateTableHeight);
  // }, [updateTableHeight]);

  if (standardTable) {
    return <div className={classes.scrollWrapper} style={{ margin, width }}>{children}</div>;
  }

  if (fullPageTable) {
    return (
      // <TableContext.Provider
      //   value={{
      //     columnWidths,
      //     tableHeight
      //   }}
      // >
      <div className={classes.scrollWrapper}>
        <Scrollbars style={{ width: "100%", height: "100%" }}>
          <div className={classes.table} style={{ width, margin }}>
            {children}
          </div>
        </Scrollbars>
      </div>
      // </TableContext.Provider>
    );
  }

  return children;
}

export default Table;
