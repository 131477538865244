import React from "react";
import { ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  tab: {
    color: "#999",
    height: 38,
    fontSize: 16,
    padding: "10px 16px",
    borderBottom: "1px solid rgba(0,0,0,0.2)",
    userSelect: "none"
  },
  activeTab: {
    color: "black",
    backgroundColor: "white",
    borderBottom: "transparent",
    borderRight: "1px solid rgba(0,0,0,0.2)",
    borderLeft: "1px solid rgba(0,0,0,0.2)",
    "&:first-child": {
      borderLeftWidth: 0
    }
  }
});

function Tab({ children, active }) {
  const classes = useStyles();

  return (
    <ButtonBase className={clsx(classes.tab, { [classes.activeTab]: active })}>
      {children}
    </ButtonBase>
  );
}

export default Tab;
