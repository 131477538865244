import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  bold: {
    fontWeight: "bold"
  }
});

function BoldText({ children }) {
  const classes = useStyles();

  return <div className={classes.bold}>{children}</div>;
}

export default BoldText;
