import React from "react";
import { makeStyles, createMuiTheme, ThemeProvider } from "@material-ui/core";

const useStyles = makeStyles({
  styleWrapper: {
    height: "100%",
    "&, & *": {
      // boxSizing: "border-box",
      fontFamily: "Maven Pro, sans-serif",
    },
  },
});

/**
 * Heading bar background:
 * #151848
 *
 * Primary button background and clickable things like checkbox:
 * #1D979D
 *
 * Secondary button background (same as primary but 33% opacity):
 * #1d979d33
 *
 * Heading backgrounds:
 * #E8F4F5
 *
 * Input backgrounds:
 * #F9FCFC
 *
 * Input borders:
 * border: 1px solid #E3E9EF;
 */

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#e9eff3",
      topBar: "#589ca1",
      footer: "#d3dfe8",
    },
    secondary: { main: "#D0DFDF", dark: "#151848" },
    primary: {
      main: "#6bbfb9",
      light: "#d0ecea",

      100: "#F9FCFC", // input backgrounds
      200: "#E3E9EF", // input borders
      300: "#F3F9FA", // table heading  backgrounds
      400: "#E8F4F5", // form heading backgrounds
    },
  },
  typography: {
    fontFamily: ["Maven Pro", "sans-serif"].join(","),
  },
});

function StyleWrapper({ children }) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.styleWrapper}>{children}</div>
    </ThemeProvider>
  );
}

export default StyleWrapper;
