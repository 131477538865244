import React from "react";
import MuiSelect from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
  select: {
    fontFamily: "Open sans",
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.primary[200]}`,
    background: theme.palette.primary[100],
    height: 32,
    borderRadius: 2,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 280
  }
}));

function MultiSelectDropdown({ value, onChange, options, ...rest }) {
  const classes = useStyles();

  return (
    <MuiSelect
      multiple
      value={value}
      onChange={event => onChange(event.target.value)}
      input={<Input />}
      className={classes.select}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MuiSelect>
  );
}

export default MultiSelectDropdown;
