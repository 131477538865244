import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  noticeBanner: {
    backgroundColor: "#D0DFDF",
    color: "#000",
    padding: 20,
    marginBottom: 20
  }
});

function NoticeBanner({ children, active }) {
  const classes = useStyles();

  return <div className={classes.noticeBanner}>{children}</div>;
}

export default NoticeBanner;
