import React from "react";
import MuiLink from "@material-ui/core/Link";

function Link({ children, onClick, ...rest }, ref) {
  return (
    <MuiLink
      ref={ref}
      onClick={(e) => {
        if (e.stopPropogation) e.stopPropogation();

        if (onClick) {
          onClick(e);
        }
      }}
      style={{
        cursor: "pointer",
        textDecoration: "none",
      }}
      {...rest}
    >
      {children}
    </MuiLink>
  );
}

export default React.forwardRef(Link);
