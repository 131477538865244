import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  tableHeader: {
    width: "100%",
    borderCollapse: "collapse"
  }
});

function TableHeader({ children }) {
  const classes = useStyles();

  return (
    <table className={classes.tableHeader}>
      <thead>
        <tr>{children}</tr>
      </thead>
    </table>
  );
}

export default TableHeader;
