import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";

import TextInput from "../TextInput";
import Link from "../Link";

function DatePicker({ value, onChange, disabled }, ref) {
  const [open, setOpen] = React.useState(false);
  const wrapperRef = React.useRef(null);

  const DatePickerInput = React.useCallback(function({
    helperText,
    InputProps,
    ...rest
  }) {
    return (
      <TextInput
        // wrapperRef={wrapperRef}
        ref={ref}
        suffix={
          <Link innerRef={wrapperRef} onClick={() => setOpen(true)}>
            choose
          </Link>
        }
        {...rest}
      ></TextInput>
    );
  },
  []);

  return (
    <KeyboardDatePicker
      open={open}
      onDismiss={() => setOpen(false)}
      placeholder="dd/mm/yyyy"
      value={value}
      onChange={value => {
        onChange(value);
        if (open) {
          setOpen(false);
        }
      }}
      format="dd/MM/yyyy"
      disabled={disabled}
      disableToolbar
      variant="inline"
      TextFieldComponent={DatePickerInput}
      PopoverProps={{
        anchorEl: wrapperRef.current,
        anchorOrigin: { horizontal: "left", vertical: "bottom" },
        transformOrigin: { vertical: "top", horizontal: "left" }
      }}
    />
  );
}

export default React.forwardRef(DatePicker);
