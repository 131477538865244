import React from "react";
import MuiSelect from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  wrapper: {
    fontFamily: "Open sans",
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.primary[200]}`,
    background: theme.palette.primary[100],
    height: 32,
    borderRadius: 2,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 280
  },
  root: {
    height: 32,
    // padding: "0 8px",
    flexGrow: 1
  },
  select: {
    fontSize: 14,
    height: 32,
    padding: "0 8px"
  }
}));

function Dropdown({ value, onChange, children, ...rest }) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <MuiSelect
        disableUnderline
        className={classes.root}
        classes={{ select: classes.select }}
        value={value}
        onChange={onChange}
        native
        {...rest}
      >
        {children}
      </MuiSelect>
    </div>
  );
}

export default Dropdown;
