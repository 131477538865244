import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  default: {
    fontSize: "24px"
  },
  large: {
    fontSize: "36px"
  },
  medium: {
    fontSize: "24px"
  },
  small: {
    fontSize: "18px"
  },
  bold: {
    fontWeight: "bold"
  }
});

function FormTitle({ children, large, medium, small, bold }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.default, {
        [classes.large]: large,
        [classes.medium]: medium,
        [classes.small]: small,
        [classes.bold]: bold
      })}
    >
      {children}
    </div>
  );
}

export default FormTitle;
