import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  /* Styles applied to the root element. */
  root: {
    height: 46,
    display: "flex",
    alignItems: "center",
    padding: "4px 16px",
    justifyContent: "flex-end",
    flex: "0 0 auto",
    borderTop: `1px solid ${theme.palette.primary[200]}`
  },
  /* Styles applied to the root element if `disableSpacing={false}`. */
  spacing: {
    "& > :not(:first-child)": {
      marginLeft: 8
    }
  }
}));

const TableFooter = React.forwardRef(function TableFooter(props, ref) {
  const { disableSpacing = false, ...other } = props;
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, { [classes.spacing]: !disableSpacing })}
      ref={ref}
      {...other}
    />
  );
});

TableFooter.height = 46;

TableFooter.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * If `true`, the actions do not have additional margin.
   */
  disableSpacing: PropTypes.bool
};

export default TableFooter;
