import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiRadio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "inline-flex",
    marginRight: 8,
    marginLeft: 0
  },
  label: {
    fontSize: 12
  },
  radio: {
    height: 32,
    width: 32,
    color: theme.palette.primary[200]
  }
}));

function Radio({ value, label, disabled }) {
  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{ root: classes.root, label: classes.label }}
      value={value}
      control={<MuiRadio className={classes.radio} color="primary" />}
      label={label}
      labelPlacement="end"
      disabled={disabled}
    />
  );
}

export default Radio;
