import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const useStyles = makeStyles({
  root: {
    // outline: "1px solid red",
    // height: 264,
    flexGrow: 1,
    width: "100%",
    paddingRight: 20
  }
});

export default function GmailTreeView({ children, defaultExpanded, ...rest }) {
  const classes = useStyles();

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={defaultExpanded}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      onNodeSelect={() => console.log("node selected")}
      {...rest}
    >
      {children}
    </TreeView>
  );
}
