import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
  root: {
    fontSize: 28,
    lineHeight: "33px",
    fontWeight: "bold",
  },
});

function PageTitle({ children, color }) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ color }}>
      {children}
    </div>
  );
}

export default PageTitle;
