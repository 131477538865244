import React from "react";

// this just wraps option, but will allow us to add props later once all dropdowns are pointing at this.
function DropdownOption({ value, children, disabled }) {
  return (
    <option disabled={disabled} value={value}>
      {children}
    </option>
  );
}

export default DropdownOption;
