import React from "react";
import propTypes from "prop-types";
import clsx from "clsx";
import MuiButton from "@material-ui/core/ButtonBase";
import { makeStyles, IconButton } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const useStyles = makeStyles(theme => ({
  iconButton: {
    // fontFamily: "Open sans",
    // boxSizing: "border-box",
    // border: `1px solid ${theme.palette.primary.main}`,
    height: 32,
    width: 32,
    borderRadius: 4,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center"
    // padding: [[0, 24]],
    // fontSize: 14,
    //lineHeight: "16px"
  },
  disabled: {
    opacity: 0.6
  }
}));

function ExpandButton({
  children,
  open,
  disabled,
  variant = "primary",
  onClick,
  ...rest
}) {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.iconButton}
      onClick={e => {
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
  );

  // return (
  //   <MuiButton
  //     className={clsx(classes.button, classes[variant], {
  //       [classes.disabled]: disabled,
  //       [classes.large]: large,
  //       [classes.small]: !large && small
  //     })}
  //     onClick={e => {
  //       //e.stopPropogation();
  //       if (onClick) {
  //         onClick(e);
  //       }
  //     }}
  //     {...rest}
  //   >
  //     {children}
  //   </MuiButton>
  // );
}

ExpandButton.propTypes = {
  children: propTypes.node,
  disabled: propTypes.bool,
  open: propTypes.bool
};

export default ExpandButton;
