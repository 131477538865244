import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import backgroundImage from "./loading.svg";

const useStyles = makeStyles({
  wrapper: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  img: {
    height: "240px",
    width: "240px",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover"
  }
});

function PageLoading() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.img} />
    </div>
  );
}

export default PageLoading;
