import React from "react";
import PropTypes from "prop-types";
import { useRifm } from "rifm";
import TextInput from "../TextInput";

function getFormatter(transform, mask, accept, addMask = false) {
  return string => {
    if (!string) {
      return string;
    }
    const digits = ((string && transform(string).match(accept)) || []).join("");
    let _count = 0;
    return mask.split("").reduce((output, char) => {
      if (!addMask && _count >= digits.length) {
        return output;
      }
      if (char !== "_" || _count >= digits.length) {
        return output + char;
      }
      return output + digits[_count++];
    }, "");
  };
}

function TextInputMasked({
  value,
  onChange,
  transform = x => x,
  accept,
  mask,
  ...rest
}) {
  const rifm = useRifm({
    mask: true,
    accept,
    format: getFormatter(x => x, mask, accept),
    replace: getFormatter(transform, mask, accept, true),
    onChange,
    value
  });

  if (accept.test(mask)) {
    // it breaks if any of the mask is accepted by the accept
    console.error(
      "You cannot use a mask that matches the accepted characters."
    );
    return <TextInput value={value} onChange={onChange} {...rest} />;
  }

  return <TextInput value={rifm.value} onChange={rifm.onChange} {...rest} />;
}

TextInputMasked.propTypes = {
  /**
   * The value of the text field.
   */
  value: PropTypes.string.isRequired,

  /**
   * The handler of changes.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * A transform function for changing the text as it is typed, useful for uppercasing or fixing to a pattern.
   */
  transform: PropTypes.func.isRequired,

  /**
   * A regex that matches single characters of the character set allowed.
   */
  accept: PropTypes.instanceOf(RegExp).isRequired,

  /**
   * The mask that dictates what can inputted into this field with the _ (underscore character).
   * No characters of the mask should match the accepted character set in the accept prop.
   */
  mask: PropTypes.string.isRequired
};

export default TextInputMasked;
