import React from "react";

export default function PanelSpacer({ height, ...rest }) {
  return (
    <div
      style={{
        width: "100%",
        height: height ?? 16,
      }}
      {...rest}
    ></div>
  );
}
