import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Scrollbars from "react-custom-scrollbars";
import { FixedSizeList as List } from "react-window";

import TableContext from "../Table/TableContext";
import VirtualizedScrollbars from "../VirtualizedScrollbars";

const useStyles = makeStyles(theme => ({
  tableBody: {
    width: "100%",
    borderCollapse: "collapse",
    background: theme.palette.background.paper
  }
}));

function TableBody({
  children,
  virtualized,
  rowHeight = 38,
  fullPageTable,
  headerHeight = 45,
  footerHeight = 0
}) {
  const classes = useStyles();
  // const listRef = React.useRef();
  // const { tableHeight } = React.useContext(TableContext);

  // console.log({ tableHeight });

  // if (virtualized) {
  //   const rows = React.Children.toArray(children);

  //   return (
  //     <List
  //       ref={listRef}
  //       className="List"
  //       height={tableHeight ? tableHeight - 33 : 100}
  //       itemCount={rows.length}
  //       itemSize={rowHeight}
  //       width="100%"
  //       outerElementType={VirtualizedScrollbars}
  //     >
  //       {({ index, style }) => {
  //         return (
  //           <table style={style} className={classes.tableBody}>
  //             <tbody>{rows[index]}</tbody>
  //           </table>
  //         );
  //       }}
  //     </List>
  //   );
  // }

  if (fullPageTable) {
    return (
      <Scrollbars
        style={{
          width: "100%",
          height: `calc(100% - ${headerHeight}px - ${footerHeight}px)`
        }}
      >
        <table className={classes.tableBody}>
          <tbody>{children}</tbody>
        </table>
      </Scrollbars>
    );
  }

  return (
    <table className={classes.tableBody}>
      <tbody>{children}</tbody>
    </table>
  );
}

export default TableBody;
