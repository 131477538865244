import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ResponsiveLayoutWrapper from "../ResponsiveLayoutWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    background:
      "linear-gradient(180deg, rgba(107,191,185,1) 0%, rgba(72,150,155,1) 100%)",
    padding: "5% 20%",
    textAlign: "center",
  },
  quickLinksTop: {
    backgroundColor: "rgba(72,150,155,1)",
    minHeight: "100px",
    height: "calc(300px - 30vw)",
  },
  quickLinksBottom: {
    backgroundColor: "#e9eff3",
    height: "calc(600px - 45vw)",
    minHeight: 100,
    maxHeight: 200,
  },
}));

export default function PageBanner({ children, quickLinks }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <ResponsiveLayoutWrapper>{children}</ResponsiveLayoutWrapper>
        </div>
        <div>
          <div className={classes.quickLinksTop}>{quickLinks}</div>
          <div className={classes.quickLinksBottom}></div>
        </div>
      </div>
    </>
  );
}
