import React from "react";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "left",
    height: "40px",
    width: "217px",
    borderRadius: "5px",
    padding: "10px 0",
    "&:hover": {
      background: "#e0e0e0"
    },
    color: "#000000",
    margin: theme.spacing(1)
  },
  icon: {
    "&>*": {
      fontSize: 40
    },
    textAlign: "center",
    height: 42,
    marginLeft: "5px"
  },
  title: {
    display: "flex",
    fontFamily: "Circular Std",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    marginLeft: 10,
  },
  selected: {
    background: "#D9F1F2",
    color: "#00A5AA"
  }
}));

function NavigationBarLink({ icon, title, component, selected, ...rest }) {
  const classes = useStyles();

  const LinkComponent = component || "div";

  return (
    <LinkComponent className={clsx(classes.root, {[classes.selected]: selected})} {...rest}>
      <div className={classes.icon}>{icon}</div>
      <div className={classes.title}>{title}</div>
    </LinkComponent>
  );
}

export default NavigationBarLink;
