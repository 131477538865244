import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    flex: 1,
    margin: "2px",
    padding: "10px",
    minWidth: "250px",
    display: "flex",
    flexDirection: "column",
  },
});

function Panel({
  children,
  active,
  maxWidth,
  height,
  width,
  maxHeight,
  ...rest
}) {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{ width: width ?? "50%", maxWidth, height, maxHeight }}
      {...rest}
    >
      {children}
    </div>
  );
}

export default Panel;
