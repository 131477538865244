import React from "react";
import { 
    SnackbarProvider,
    useSnackbar
} from "notistack";

function SnackProvider(props) {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
    >
      <Wrapper {...props} />
    </SnackbarProvider>
  );
}

function Wrapper({ children, subscribe, unsubscribe }) {
  const es = useSnackbar();

  const showMessage = React.useCallback(event => {
    es.enqueueSnackbar(event.data);
  }, []);

  React.useEffect(() => {
    subscribe(showMessage);
    return () => unsubscribe(showMessage);
  }, [subscribe, unsubscribe]);

  return React.Children.only(children);
}

export default SnackProvider;
