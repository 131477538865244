import * as React from "react";

/**
 * @ignore - internal component.
 */
const TableContext = React.createContext({
  columnWidths: [],
  tableHeight: 100
});

if (process.env.NODE_ENV !== "production") {
  TableContext.displayName = "TableContext";
}

export default TableContext;
