import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ResponsiveLayoutWrapper from "../ResponsiveLayoutWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    width: "100%",
    minHeight: 200,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    margin: "24px 12px",
    borderRadius: 16,
    padding: "5% 20%",
    textAlign: "center",
  },
}));

function PageHeadingArea({ children }) {
  const classes = useStyles();

  return (
    <ResponsiveLayoutWrapper>
      <div className={classes.root}>{children}</div>
    </ResponsiveLayoutWrapper>
  );
}

export default PageHeadingArea;
